import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { searchPlugin } from "@react-pdf-viewer/search";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import * as pdfjs from "pdfjs-dist";
import stringSimilarity from "string-similarity";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";

const pdfLinkBaseUrl = process.env.REACT_APP_PDF_LINK_BASE_URL;

const PDFViewer = () => {
    const [searchParams] = useSearchParams();
    const [pdfUrl, setPdfUrl] = useState(null);
    const [highlightText, setHighlightText] = useState("");
    const [matchedTexts, setMatchedTexts] = useState([]); // 類似したテキストのリスト
    const [hasJumped, setHasJumped] = useState(false);
    const [pdfLoaded, setPdfLoaded] = useState(false);

    // プラグインのインスタンス
    const searchPluginInstance = searchPlugin();
    const pageNavigationPluginInstance = pageNavigationPlugin();

    // ハイライトとページナビゲーション用の関数
    const { highlight } = searchPluginInstance;
    const { jumpToPage } = pageNavigationPluginInstance;

    useEffect(() => {
        // URLパラメータを取得
        const url = pdfLinkBaseUrl + searchParams.get("url");
        const highlight = searchParams.get("highlight");
        const page = searchParams.get("page");

        if (url) {
            setPdfUrl(url);
        }

        if (highlight) {
            try {
                setHighlightText(decodeURIComponent(highlight));
            } catch (error) {
                console.error("Error decoding highlight parameter:", error);
            }
        }

        // ページ番号が指定され、PDFがロードされ、まだジャンプしていない場合に実行
        if (page && pdfLoaded && !hasJumped && matchedTexts.length === 0) {
            const pageIndex = parseInt(page, 10) - 1;
            console.log(pageIndex);
            if (!isNaN(pageIndex)) {
                jumpToPage(pageIndex);
                setHasJumped(true);
            }
        }
    }, [searchParams, jumpToPage, hasJumped, matchedTexts, pdfLoaded]); // pdfLoaded を追加

    useEffect(() => {
        const extractAndMatchText = async () => {
            if (!pdfUrl || !highlightText) return;

            const loadingTask = pdfjs.getDocument(pdfUrl);
            const pdf = await loadingTask.promise;

            const numPages = pdf.numPages;
            let similarTexts = [];

            for (let i = 1; i <= numPages; i++) {
                const page = await pdf.getPage(i);
                const textContent = await page.getTextContent();

                const pageText = textContent.items.map((item) => item.str).join(" ");
                const matches = stringSimilarity.findBestMatch(highlightText, pageText.split(" "));
                const threshold = 0.8;

                const filteredMatches = matches.ratings
                    .filter((rating) => rating.rating >= threshold)
                    .map((match) => ({
                        text: match.target,
                        pageIndex: i - 1,
                    }));

                similarTexts = [...similarTexts, ...filteredMatches];
            }

            if (JSON.stringify(similarTexts) !== JSON.stringify(matchedTexts)) {
                console.log("Updating matched texts:", similarTexts);
                setMatchedTexts(similarTexts);
            }
        };

        extractAndMatchText();
    }, [pdfUrl, highlightText]);

    useEffect(() => {
        if (matchedTexts.length > 0) {
            const keywords = matchedTexts.map((match) => ({
                keyword: match.text,
                pageIndex: match.pageIndex,
            }));

            console.log("Highlighting keywords:", keywords);
            highlight(keywords);
        }
    }, [matchedTexts]);

    // PDFロード時のハンドラー
    const handleDocumentLoad = () => {
        setPdfLoaded(true);
    };

    return (
        <div style={{ position: "relative", height: "100vh", display: "flex", justifyContent: "center" }}>
            {pdfUrl ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.10.111/build/pdf.worker.min.js">
                    <div style={{ width: "60%", height: "auto", margin: "0 auto" }}>
                        <Viewer
                            fileUrl={pdfUrl}
                            plugins={[searchPluginInstance, pageNavigationPluginInstance]}
                            onDocumentLoad={handleDocumentLoad} // PDFロード時のイベント
                        />
                    </div>
                </Worker>
            ) : (
                <p>PDF URLが指定されていません。</p>
            )}
        </div>
    );
};

export default PDFViewer;

