import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; 
import Select from 'react-select';
import { chatQuery, getBasicQuestions, fetchCategories } from '../utils/apiRequest'; 
import './ChatbotPage.css';

const STATES = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D'
};

function ChatbotPage() {
    const navigate = useNavigate();

    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [category, setCategory] = useState(null);
    const [categories, setCategories] = useState([]);
    const [state, setState] = useState(STATES.A);
    const [basicQuestions, setBasicQuestions] = useState([]);
    const [mainInformation, setMainInformation] = useState([]);
    const [additionalInformation, setAdditionalInformation] = useState([]);
    const [isQuerying, setIsQuerying] = useState(false);
    const endOfMessagesRef = useRef(null);
    const [initialUserQuery, setInitialUserQuery] = useState('');
    const pdfLinkBaseUrl = process.env.REACT_APP_PDF_LINK_BASE_URL;

    useEffect(() => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    useEffect(() => {
        const loadCategories = async () => {
            const categoriesData = await fetchCategories();
            const categoryOptions = categoriesData.categories.map(cat => ({ value: cat.id, label: cat.name }));
            setCategories(categoryOptions);

            if (categoryOptions.length > 0) {
                setCategory(categoryOptions[0]);
            }
        };
        loadCategories();

        const initialMessage = { index: "0", role: 'AI', text: "こんにちは。調べたいカテゴリを選択してご質問をどうぞ。" };
        streamBotResponse(initialMessage.text, true);
    }, []);

    useEffect(() => {
        if (state === STATES.A && basicQuestions.length > 0) {
            setState(STATES.B);
            streamBotResponse("以下の選択肢から該当するものを選択してクリックして下さい。", () => {
                displayOptions(basicQuestions.slice(0, 5), true);
            });
        }
    }, [basicQuestions]);

    useEffect(() => {
        if (state === STATES.C) {
            displayOptions(basicQuestions.slice(5), false);
        }
    }, [state]);

    useEffect(() => {
        if (state === STATES.D) {
            if (!isQuerying) {
                setIsQuerying(true);
                chatQueryAPI();
            }
        }
    }, [state]);

    const chatQueryAPI = async () => {
        const updatedHistory = [...messages, { role: 'User', text: initialUserQuery }];
        const response = await chatQuery(initialUserQuery, updatedHistory, category.value, mainInformation, additionalInformation);
        streamBotResponse(response.answer, () => {
            displayReferenceLink(); // 参考情報のPDFリンクを表示する
            setState(STATES.A);
            setIsQuerying(false);
        });
    };

    const handleSendMessage = async (userMessage = null) => {
        if ((input.trim() !== '' && category) || userMessage) {
            const userQuery = userMessage || input;
            const newMessage = { index: messages.length.toString(), role: 'User', text: userQuery };
            setMessages(msgs => [...msgs, newMessage]);

            if (state === STATES.A) {
                setInitialUserQuery(userQuery);
                setIsQuerying(true);
                const response = await getBasicQuestions(userQuery, category.value);
                console.log("Basic Questions Response:", response.basic_questions);
                setBasicQuestions(response.basic_questions);
                setIsQuerying(false);
            }
            setInput('');
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !isQuerying) {
            handleSendMessage();
        }
    };

    const handleClickableResponse = (text) => {
        const newMessage = { index: messages.length.toString(), role: 'User', text };
        setMessages(msgs => [...msgs, newMessage]);
        handleSendMessage(text);
    };

    const handleBasicQuestionClick = useCallback(async (index) => {
        let adjustedIndex = index;

        if (state === STATES.C) {
            adjustedIndex += 5; // 6-10番目のインデックスを正しく1-5に変換
        }

        const selectedQuestion = basicQuestions[adjustedIndex];
        const remainingQuestions = basicQuestions.filter((_, i) => i !== adjustedIndex);

        setMainInformation([selectedQuestion.answer || '']);
        setAdditionalInformation(remainingQuestions.map(q => q.answer || ''));

        const newMessage = { index: messages.length.toString(), role: 'User', text: selectedQuestion.question };
        setMessages(msgs => [...msgs, newMessage]);

        setState(STATES.D);
    }, [basicQuestions, category, messages, state]);

    const handleSpecialOptionClick = useCallback((text, newState) => {
        const newMessage = { index: messages.length.toString(), role: 'User', text };
        setMessages(msgs => [...msgs, newMessage]);

        setState(newState);
    }, [messages]);

    const streamBotResponse = (text, callback = null) => {
        const chars = text.split('');
        let index = 0;
        const interval = setInterval(() => {
            if (index < chars.length) {
                const currentText = chars.slice(0, index + 1).join('');
                setMessages(msgs => {
                    const lastMessage = msgs[msgs.length - 1];
                    if (lastMessage && lastMessage.role === 'AI') {
                        return [...msgs.slice(0, -1), { ...lastMessage, text: currentText }];
                    } else {
                        return [...msgs, { index: (msgs.length).toString(), role: 'AI', text: currentText }];
                    }
                });
                index++;
            } else {
                clearInterval(interval);
                if (callback && typeof callback === 'function') {
                    callback();
                }
            }
        }, 50);
    };

    const displayOptions = (options, showNextQuestion) => {
        setMessages(msgs => {
            const optionsText = options.map((q, i) => (
                <p key={i} onClick={() => handleBasicQuestionClick(i)} className="clickable-text">{q.question}</p>
            ));
            if (showNextQuestion) {
                optionsText.push(<p key={options.length} onClick={() => handleSpecialOptionClick('次の質問', STATES.C)} className="clickable-text">次の質問</p>);
            } else {
                optionsText.push(<p key={options.length} onClick={() => handleSpecialOptionClick('その他', STATES.D)} className="clickable-text">その他</p>);
            }
            return [...msgs, { index: (msgs.length).toString(), role: 'AI', text: "", options: optionsText }];
        });
    };

    const displayReferenceLink = () => {
	if (mainInformation.length > 0 && basicQuestions.length > 0) {
	    const selectedQuestion = basicQuestions.find(q => q.answer === mainInformation[0]);
	    if (selectedQuestion) {
		const referenceLink = (
		    <p key="reference">
			【参考リンク】<span
			    className="clickable-text"
				onClick={() =>
				    window.open(
					`/pdfview?url=${encodeURIComponent(selectedQuestion.md5hash + ".pdf")}&page=${selectedQuestion.page}&highlight=${encodeURIComponent(selectedQuestion.block_text)}`,
					"_blank", // 別タブで開く
					"noopener,noreferrer" // セキュリティ対策
				    )
				}
	                >
                        	{selectedQuestion.question}
			</span>
		    </p>
		);
		setMessages(msgs => [...msgs, { index: (msgs.length).toString(), role: 'AI', text: "", options: [referenceLink] }]);
	    }
	}
    };

    return (
	<div className="no-sidebar content-wrapper p-3">
	    <div className="card card-primary card-outline">
		<div className="card-body">
		    <div className="chat-area" style={{ height: '500px', overflowY: 'scroll' }}>
			{messages.map((msg, index) => (
			    <div key={index} className={`chat-message ${msg.role.toLowerCase()}`}>
				<span className={`chat-icon ${msg.role.toLowerCase()}-icon`}>
				    <i className={`fas fa-${msg.role.toLowerCase() === 'user' ? 'user' : 'robot'}`}></i>
				</span>
				<div className="chat-text">
				    {msg.role === 'AI' && msg.options
					? msg.options
					: msg.text.includes('<clickable>')
					    ? <span onClick={() => handleClickableResponse(msg.text.replace('<clickable>', ''))} className="clickable-text">{msg.text.replace('<clickable>', '')}</span>
					    : msg.text
				    }
				</div>
			    </div>
			))}
			<div ref={endOfMessagesRef} />
		    </div>
		    <div className="input-group my-3">
			<Select
			    className="select2"
			    classNamePrefix="select"
			    value={category}
			    onChange={setCategory}
			    options={categories}
			    placeholder="Select a category..."
			    styles={{ container: (base) => ({ ...base, width: 200 }) }}
			    isDisabled={isQuerying || state !== STATES.A} // クエリ中またはSTATEがA以外のときはカテゴリー選択を無効化
			/>
			<input
			    type="text"
			    className="form-control"
			    value={input}
			    onChange={e => setInput(e.target.value)}
			    onKeyPress={handleKeyPress}
			    placeholder="Type your message..."
			    disabled={isQuerying || state !== STATES.A} // クエリ中またはSTATEがA以外のときは入力ボックスを無効化
			/>
			<div className="input-group-append">
			    <button className="btn btn-primary" onClick={() => handleSendMessage()} disabled={isQuerying || state !== STATES.A}>
				Send
			    </button>
			</div>
		    </div>
	            <button 
                        className="btn btn-secondary mb-3" 
                        onClick={() => navigate('/vectordb')} 
                    >
                        &lt; 戻る
                    </button>
		</div>
	    </div>
	</div>
    );

}

export default ChatbotPage;

